.about-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 2vw;
  }
  
  .team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin: 2vw;
  }
  
  .team-image {
    width: 10vw;
    height: 10vw;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .member-info {
    text-align: center;
  }
  
  .member-info h3 {
    font-family: 'Teko', sans-serif;
    font-weight: bold;
    font-size: 2vw;
    margin: 5px 0;
  }

  h4 {
    font-family: 'Teko', sans-serif;
    font-size: 1.5vw;
    color: #666
  }
  

  @media screen and (max-width: 1100px) {
    .team-image {
      width: 15vw;
      height: 15vw;
    }
    .member-info h3 {
      font-size: 3vw;
    }
    h4 {
      font-size: 2vw;
    }
  }

  @media screen and (max-width: 650px) {
    .team-image {
      width: 25vw;
      height: 25vw;
    }
    .member-info h3 {
      font-size: 5vw;
    }
    h4 {
      font-size: 4vw;
    }
  }
