@import url('https://fonts.googleapis.com/css2?family=Teko:wght@400;600&display=swap');

body, html {
    height: 100%;
    margin: 0;
}

#page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: F5F5F5;
}

#content-wrap {
    flex: 1; /* This makes the content area grow to fill the remaining space */
}

p {
    font-family: 'Teko', sans-serif;
    font-weight: 300;
    font-size: 2vw;
    padding: 6vh;
}

.infoContainer {
    display: flex;
    align-items: center;
}

.slogan-section {
    position: relative;
    text-align: left;
}

.slogan-img {
    border-radius: 0vw !important;
    padding: 0vh !important;
    width: 100vw;
    height: 55vw;
}

.slogan-text {
    position: absolute;
    padding: 2vh;
    top: 1vw;
    left: 3vw;
    width: 70%;
}
.vignette {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    box-shadow: 
        40vw 0px 100vw -10vw rgba(0,0,0,0.4) inset,
        20vw 0px 100vw -10vw rgba(0,0,0,0.5) inset;
}
h1 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 6vw !important;
    color: whitesmoke !important;
    font-family: 'Teko', sans-serif;
}
h2 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 7vw !important;
    color: #6B8E23 !important;
    font-family: 'Teko', sans-serif;
}

.productBanner {
    height: 30vw;
    width: 75%;
    background-color: #1B2375;
    border-top-right-radius: 15vw 15vw;
    border-bottom-right-radius: 15vw 15vw;
    margin-top: 7vw;
    display: flex;   
} 
.productBanner:hover {
    height: 32vw;
    width: 80%;
    margin-top: 6vw;
    padding-top: 1vw;
}
.productBannerText {
    max-width: 50%;
    margin-left: 10vw;
    margin-top: 10vw;
}
.productBannerPic {
    height: 30vw;
    margin-left: 7vw;
}

h3 {
    font-family: 'Teko', sans-serif;
    font-weight: 300;
    font-size: 3vw !important;
    color: whitesmoke !important;
}

img {
    max-width: 100%;
    max-height: 100%;
}


.storyBanner {
    height: 30vw;
    width: 75%;
    background-color: #1B2375;
    border-top-left-radius: 15vw 15vw;
    border-bottom-left-radius: 15vw 15vw;
    margin-top: 7vw;
    display: flex; 
    margin-left: auto;
} 
.storyBanner:hover {
    height: 32vw;
    width: 80%;
    margin-top: 6vw;
    padding-top: 1vw;
}
.storyBannerText {
    max-width: 50%;
    margin: auto;
}

.demoBanner {
    height: 30vw;
    width: 75%;
    background-color: #1B2375;
    border-top-right-radius: 15vw 15vw;
    border-bottom-right-radius: 15vw 15vw;
    margin-top: 7vw;
    margin-bottom: 7vw;
    display: flex;   
} 
.demoBanner:hover {
    height: 32vw;
    width: 80%;
    margin-top: 6vw;
    padding-top: 1vw;
}
.demoBannerText {
    max-width: 50%;
    margin: auto;
}


.textAndPicture {
    display: flex;
    flex-direction: row;
}
.pictureAndText {
    display: flex;
    flex-direction: row;
}
.image {
    width: 250vw;
    margin: 4vw;
}

h5 {
    font-family: 'Teko', sans-serif;
    font-weight: 300;
    font-size: 4vw !important;
    color: #1B2375 !important;
}


:root {
    --cui-tertiary-bg-rgb: 27, 35, 117 !important;
    --cui-emphasis-color-rgb: 255, 255, 255 !important;
}
.navbar-toggler {
    background-color: #FFFF !important;
}

.footer{
    --cui-footer-bg: rgb(27, 35, 117) !important;
    --cui-footer-color: rgb(255, 255, 255) !important;
}

.splineComponent {
    width: 100vw;
    height: 100vh;
}

@media screen and (max-width: 500px) {
    p {
        font-size: 3vw;
        padding: 2vh;
    }

    h5 {
        font-size: 3vh !important;
    }

}

@media screen and (max-width: 700px) {
    .textAndPicture {
        flex-direction: column;
        padding-left: 7vw;
        padding-right: 7vw;
    }

    .pictureAndText {
        flex-direction: column-reverse;
        padding-left: 7vw;
        padding-right: 7vw;
    }

    .image {
        width: 50vw;
        margin: auto;
    }
}

@media screen and (min-width: 2700px) {
    .homeContent {
        align-items: center;
        padding-left: 10vw;
        padding-right: 10vw;
    }
}
